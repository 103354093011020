import React from 'react';
import logoPlaizt from "../Imagenes/Logo.svg"
import decoracion from "../Imagenes/img-decoracion.svg"
import "./Navbar.css"

const Navbar = () => {

    return (
        
        <>
            <nav className='navbar'>
                <img src={logoPlaizt} alt='logoPlaizt' className='logoPlaizt' style={{position:"absolute", left: "23px", width:"130px", marginTop: "38px" }} ></img>
                <img src={decoracion} alt='decoracion' style={{ position:"absolute",  right: "0px", marginBottom:"20px" }} className='decoracion'></img>
            </nav>               
        </>      
        
    )
}

export default Navbar