import React, { useEffect, useState } from "react";
import Select from "react-select";
//import logoCompany from "../Imagenes/LogoCompany.svg";
import "./Formulario.css";

// npm i --save react-select >>>> es un paquete de react para hacer el select.

export const Formulario = (props) => {
  const { addressList, obtenerEspaciosDisponibles, logoCompany } = props;

  // muestra la informacion en el select
  const restaurantSelect = [];
  for (let i = 0; i < addressList.length; i++) {
    const address = addressList[i];
    // console.log(address);
    restaurantSelect.push({ id: i, value: address });
    // id: i es la posicion en el array ... sucursalseleccionada
  }

  const numberPeople = [];
  for (let i = 1; i < 9; i++) {
    numberPeople.push({ id: i, q: i + " Personas" });
  }

  const [registros, setRegistros] = useState([]);

  const [error, setError] = useState();
  const [indexSucursal, setSucursal] = useState();
  // console.log(indexSucursal);
  const [date, setDate] = useState(getFechaHoy);
  /////

  const [fecha, setFecha] = useState(getFechaHoy);

  function getFechaHoy() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Asegurarse de que el mes y el día tengan dos dígitos
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }
  const handleFechaChange = (event) => {
    setFecha(event.target.value);
  };
  ////

  // const FormatDate = new Date().toISOString().slice(0,10);
  const [selectPerson, setSelectPerson] = useState();

  const guardarInfo = () => {
    var infoForm = { indexSucursal, selectPerson, date };
    setRegistros([...registros, infoForm]);

    // Guarda info en el localStorage
    localStorage.setItem("indexSucursal", indexSucursal);
    localStorage.setItem("Fecha", date);
    localStorage.setItem("Personas", selectPerson);
    obtenerEspaciosDisponibles();
  };

  useEffect(() => {
    try {
      const localStorageAnwers = localStorage.setItem("registros");

      let parsedRespuesta;

      if (!localStorageAnwers) {
        localStorage.setItem("registros", JSON.stringify(registros)); // envia info en forma de string.

        parsedRespuesta = registros;
      } else {
        parsedRespuesta = JSON.parse(localStorageAnwers.getItem("registros")); // JSON.parse se convierte la información en un objeto de javascript.
      }
      setRegistros(parsedRespuesta);
    } catch (error) {
      setError(error);
    }
  }, []);

  const onChangeSelect = (e) => {
    setSucursal(e.value);
  };

  const onChangeNumberPeople = (e) => {
    setSelectPerson(e.value);
  };

  return (
    <>
      <img
        src={logoCompany}
        alt="logoCompany"
        style={{ width: "94.17px", height: "80.75px", marginTop: "150px" }}
      ></img>

      <form className="form">
        <label className="title">Restaurante / Sucursal </label>

        <Select
          id="sucursal"
          className="options"
          defaultValue={{
            label: "Selecciona el restaurante",
            value: indexSucursal,
          }}
          options={restaurantSelect.map((restaurant) => ({
            label: restaurant.value,
            value: restaurant.id,
          }))}
          onChange={onChangeSelect}
          theme={(theme) => ({
            ...theme,
            borderRadius: 12,
            width: 280,
            colors: {
              ...theme.colors,
              primary25: "#F3F2F4",
              primary: "#00F2D7",
              primary50: "#00F2D7",
            },
          })}
        />

        <label>Fecha</label>
        <input
          type="date"
          className="date"
          onChange={handleFechaChange}
          value={fecha}
          name="star"
          min={2023 - 3 - 30}
          max={2025 - 5 - 30}
        ></input>

        <label>Número de personas</label>
        <Select
          id="selectNumberPeople"
          className="options"
          defaultValue={{
            label: "Selecciona el número de personas",
            value: selectPerson,
          }}
          options={numberPeople.map((person) => ({
            label: person.q,
            value: person.id,
          }))}
          onChange={onChangeNumberPeople}
          theme={(theme) => ({
            ...theme,
            borderRadius: 12,
            width: 280,
            colors: {
              ...theme.colors,
              primary25: "#F3F2F4",
              primary: "#00F2D7",
              primary50: "#00F2D7",
            },
          })}
        />
      </form>

      <button
        className="boton"
        onClick={guardarInfo}
        style={{
          margin: "40px",
          border: "1px solid #00F2D7",
          background: "#00F2D7",
          padding: "9px",
          cursor: "pointer",
          width: "272px",
          borderRadius: "12px",
        }}
      >
        Siguiente
      </button>
    </>
  );
};

export default Formulario;
