import React, { useEffect, useState } from 'react';
import "./ConfirmacionDatos.css";
import lineaPunteada from "../Imagenes/linea-punteada.svg";
import Navbar from '../Navbar/Navbar';

export const ConfirmacionDatos = props => {

    const {actualizarPagina,logoCompany } = props;

    const [respuesta, setRespuesta] = useState([]);

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [idUser, setIdUser] = useState();
    const [phone, setPhone] = useState();
    const [error, setError] = useState(false);
    
    // obtengo info del localStorage, por eso se guarda en una constante.
    const id = localStorage.getItem("idHorarioSelec");
    const check_in = localStorage.getItem("checkIn");
    const check_out = localStorage.getItem("checkOut");
    const table_name = localStorage.getItem("tableName");
    const time = localStorage.getItem("time");
    const fecha = localStorage.getItem("Fecha");

    const nombreSucursal = localStorage.getItem("nombreSucursal"); 
    const addressSucursal = localStorage.getItem("addressSucursal"); 

    const saveInfoLocalStorage = () => {
        
        if (name === undefined || email === undefined  || phone === undefined){
            alert("Por favor ingresar todos los datos");
        } else {
            localStorage.setItem("name", name); // guarda info en el localStorage, no retorna nada
            localStorage.setItem("email", email);
            localStorage.setItem("idUser", idUser);
            localStorage.setItem("phone", phone);   
            actualizarPagina();
        }
    }

    useEffect(() => {
        try{
            const saveInfoLocal = localStorage.setItem("respuesta");
            let parsedRespuesta;
            if(!saveInfoLocal) { // si no-...
                localStorage.setItem("respuesta", JSON.stringify(respuesta));
                parsedRespuesta = respuesta; // respuesta es el estado 
            } else {
                parsedRespuesta = JSON.parse(saveInfoLocal.getItem("respuesta"));
            }
            setRespuesta(parsedRespuesta);
        } catch {
            setError(error);
        }
    }, [])

    return (
        <>

            <div className='encabezado'>
                <Navbar />
                <img src={logoCompany} alt='logoCompany' style={{ position:"absolute", width:"65px", right: "16px", marginTop: "37px" }}></img>
            </div>

            <div className='containerPrincipal' id={id}>      
                <section className='containerConfirmacion'>
                    <div className='element-info'>
                        <h4 className='hora'>{check_in}</h4>
                        <p className='check'>Check IN</p>
                    </div>
                    <div className='element-info'>
                        <h5 className='duracionReserva'>{time}</h5>
                        <img src={lineaPunteada} alt="img"></img>
                    </div>
                    <div className='element-info'>
                        <h4 className='hora'>{check_out}</h4>
                        <p className='check'>Check OUT</p>
                    </div>
                </section>

                <div className='element-Confirmacion'>
                    <h3 className='confirmacion'> {nombreSucursal} {addressSucursal} </h3>  
                    <h3 className='confirmacion'>{fecha}, {table_name}</h3>
                </div>
            </div>

            
            <form className='form'>
                
                <label className='label'>Nombre completo</label>
                    
                <input type="text" className='inputConfirmacion' placeholder='Nombre completo' onChange={(e) => setName(e.target.value)}></input>

                <label>Correo electrónico</label>
                <input type="text" className='inputConfirmacion' placeholder='mrdoe@doemail.com' onChange={(e) => setEmail(e.target.value)}></input>
                
                <label>Número de identificación</label>
                <input type="number" className='inputConfirmacion' placeholder='8888888' onChange={(e) => setIdUser(e.target.value)}></input>
                
                <label>Número de teléfono</label>
                <input type="number" className='inputConfirmacion' placeholder='Celular' onChange={(e) => setPhone(e.target.value)}></input>

            </form>

            <button 
                className="boton" 
                onClick={saveInfoLocalStorage}
                style={{ margin: "40px", border: "1px solid #00F2D7", background: "#00F2D7", padding: "9px",
                    cursor: "pointer",
                    width: "272px",
                    borderRadius: "12px" 
                }} 
            >
              Siguiente 
            </button>
        </>
    )
}
