import React from 'react';
import Navbar from '../Navbar/Navbar';
import '../SelectHorario/SeleccionHorario.css';
import { DiseñoTarjeta } from './DiseñoTarjeta';

export const SeleccionHorario = props => {

    const { espaciosDisponibles, actualizarPagina,logoCompany } = props;
    console.log(espaciosDisponibles); // Se trae toda la lista de espacios disponibles, para mostrar toda la informacion. 

    
    return (

        <>  
            <div className='encabezado'>
                <Navbar />
                <img src={logoCompany} alt='logoCompany' style={{ position:"absolute", width:"65px", right: "16px", marginTop: "37px" }}></img>
            </div>

            {
                espaciosDisponibles.map((espacio, i) => {
                    console.log(espacio.table_name);
                    return (
                        <DiseñoTarjeta id={espacio.id} check_in={espacio.check_in} check_out={espacio.check_out} table_name={espacio.table_name} time={espacio.time}  actualizarPagina={actualizarPagina} />
                    )
                })
            }
    
        </>
        
    )
}
