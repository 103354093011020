import { useEffect, useState } from 'react';
import './App.css';
import { ConfirmacionDatos } from './componentes/ConfirmacionDatosCliente/ConfirmacionDatos';
import { ConfirmarDatos } from './componentes/Confirmar datos/ConfirmarDatos';
import Formulario from './componentes/Formulario/Formulario';
import { GraciasFin } from './componentes/GraciasFin/GraciasFin';
import Navbar from './componentes/Navbar/Navbar';
import { SeleccionHorario } from './componentes/SelectHorario/SeleccionHorario';

function AppReservas() {

  const [infoApi, setInfoApi] = useState([]);
  // primer endPoint
  const urlApi = `https://dev-encuesta.api.pleizt.co/bookings/650a57cc733b8`;

  useEffect(() => {
    (async function() {
      const res = await fetch(urlApi).then ((res) => res.json());
      setInfoApi(res.data);
       console.log(res.data);
      setPaginaActual(0);
    })();
  }, [urlApi])

  // este estado guarda la informacion del segundoEndPoint.
  const [infoEspaciosDispo, setInfoEspaciosDispo] = useState([]);

  // estado para el cambio de la pagina
  const [paginaActual, setPaginaActual] = useState();
  
  // get // segundo endPoint
  const obtenerEspaciosDisponibles = async () => {
    let indexSucursal = localStorage.getItem("indexSucursal"); // la posicion dentro de infoApi de la sucursal o tienda seleccionada por el usuario.
    let fecha = localStorage.getItem("Fecha");
    let personas = localStorage.getItem("Personas");
    let sucursal = infoApi.branchs[indexSucursal];  // esta buscando la informacion completa de la sucursal seleccionada. o esta buscando la sucursal seleccionada.
 
    localStorage.setItem("nombreSucursal", sucursal.name);
    localStorage.setItem("idSucursal", sucursal.id);
    localStorage.setItem("addressSucursal", sucursal.address); 
    
    let idSucursal = sucursal.id;
    console.log(sucursal.name);
    console.log(sucursal.address);

    // segundo endPoint, aqui no es necesario hacer otro useEffect
    // esta const llama el segundo Endpoint para mostrar la lista de espacios disponibles para la reserva.
   // const urlEspaciosDispo = `https://stores.pleizt.com/api/bookingspaces?date=${fecha}&id_shop=${idSucursal}&persons=${personas}`;
   const urlEspaciosDispo = `https://dev-encuesta.api.pleizt.co/bookings/spaces/${fecha}/${idSucursal}/${personas}`;
//
    const res = await fetch(urlEspaciosDispo).then ((res) => res.json());
    setInfoEspaciosDispo(res.data); // sea diferente a undefined sea muestra la pagina siguiente.
    // console.log(res.data);

    actualizarPagina();
  }
  

  // Actualiza la pagina
  const actualizarPagina = () => {
    let posicion = paginaActual + 1;
    // se actualiza el estado y redibuja o muestra la pagina siguiente.
    setPaginaActual(posicion);
  }
  

  let display = () => {
    if(infoApi.length != 0 ) {
        let data = infoApi.branchs;
        const addressList = data.map((item) => item.name);
        console.log(addressList);
        
      
      return (
        <>
          {(()=>{

                  if(paginaActual === 0){
                    return(
                      <Formulario addressList={addressList}  obtenerEspaciosDisponibles={obtenerEspaciosDisponibles}  logoCompany={infoApi.logo}/>
                    );
                  }

                  if(paginaActual === 1) {
                    return (
                      <SeleccionHorario espaciosDisponibles={infoEspaciosDispo} actualizarPagina={actualizarPagina}  logoCompany={infoApi.logo} />
                    );
                  }

                  if(paginaActual === 2 ) {
                    return(
                      <ConfirmacionDatos actualizarPagina={actualizarPagina}  logoCompany={infoApi.logo}/>
                    );
                  }

                  if(paginaActual === 3) {
                    return(
                      <ConfirmarDatos actualizarPagina={actualizarPagina}  logoCompany={infoApi.logo}  />
                    );
                  }

                  if(paginaActual === 4 ) {
                    return(
                      <GraciasFin  logoCompany={infoApi.logo} />
                    )
                  }
          })()}
        </>
      )
    }
  }


  return (
    <div className="App">
      <>
        {(() => {
          return (
            <>
              <Navbar />
              { display() }
            </>
          )
        })()}
      </>
    </div>
  );
}

export default AppReservas;
