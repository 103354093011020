import React, { useEffect, useState } from 'react';
import '../SelectHorario/SeleccionHorario.css';
import lineaPunteada from "../Imagenes/linea-punteada.svg";

export const DiseñoTarjeta = props => {
    
    const { id, check_in, check_out, table_name, time, actualizarPagina } = props;

    const [registros, setRegistros] = useState([]);
    const [error, setError] = useState();
    // const [userSelection, setUserSelection] = useState();


    const saveInfoLocalStorage = (e) => {
        setRegistros(e.value);

        localStorage.setItem("idHorarioSelec", id);
        localStorage.setItem("checkIn", check_in);
        localStorage.setItem("checkOut", check_out);
        localStorage.setItem("tableName", table_name);
        localStorage.setItem("time", time);

        actualizarPagina();
    }

    useEffect(() => {
        try{
            const localStorageAnwers = localStorage.setItem("registros");

            let parsedRespuesta;

            if(!localStorageAnwers) {
                localStorage.setItem("registros",JSON.stringify(registros));

                parsedRespuesta = registros;
            } else {
                parsedRespuesta = JSON.parse(localStorageAnwers.getItem("registros"));

            }
            setRegistros(parsedRespuesta);
        } catch (error){
            setError(error);
        }
    }, [])

    return (

        <>
            <section className='container' >
                <div className='element-info'>
                    <h4 className='hora'>{check_in}</h4>
                    <p className='check'>Check IN</p>
                </div>
                <div className='element-info'>
                    <h5 className='duracionReserva'>{time}</h5>
                    <img src={lineaPunteada} alt="img"></img>
                </div>
                <div className='element-info'>
                    <h4 className='hora'>{check_out}</h4>
                    <p className='check'>Check OUT</p>
                </div>
                
            </section>
            <div className='element-CheckIn' onClick={saveInfoLocalStorage} >
                <h3 className='reservar'>{table_name}</h3>  
                <h3 className='reservar'>Reservar 👉 →</h3>
            </div>
            <div className='boxEnd'></div>
        
        </>
    )
}
