import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppReservas from './AppReservas';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      // <ApiContextProvider>
          <AppReservas />
      // </ApiContextProvider>
  // </React.StrictMode> 
);
