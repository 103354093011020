import React from 'react';
import Navbar from '../Navbar/Navbar';


export const GraciasFin = ({ logo }) => {
    
    let mensajeFinal = localStorage.getItem("mensajeFinal");
    console.log(mensajeFinal);

    return (
        <>
            <Navbar logo={logo} />  
                <section style={{display: "flex", flexDirection: "row", justifyContent: "center", }}>
                    <h1 style={{marginTop: "190px"}}> Gracias por reservas, le llegara un correo de confirmación de la reserva.</h1>
                </section>
        </>
    )
}



